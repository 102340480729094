import React from 'react';

/* This example requires Tailwind CSS v2.0+ */
const events = [
	{
		time: '7:00 PM ET',
		title: '“State of the Union” with Paul Reed Smith',
	},
	{
		time: '7:20 PM ET',
		title: 'Welcome to Experience with Jack Higginbotham (COO) and Jamie Mann (President)',
	},
	{
		time: '7:40 PM ET',
		title: 'From the Factory Floor: Maryland Made Electrics',
	},
	{
		time: '8:00 PM ET',
		title: 'Artist Demo: Ben Eller on the PRS Archon',
	},
	{
		time: '8:20 PM ET',
		title: 'Artist Clinics w/ Corey Congilio & Justin Derrico',
	},
	{
		time: '8:40 PM ET',
		title: 'Jam Break w/ Tyler Larson & Bryan Ewald',
	},
	{
		time: '8:40 PM ET',
		title: 'From the Factory Floor: PRS SE Series and PRS Amplifiers',
	},
	{
		time: '8:40 PM ET',
		title: 'Artist Demo: Wes Hauch on the PRS Archon',
	},
	{
		time: '8:40 PM ET',
		title: 'Artist Clinics w/ Tenille Arts, Isaiah Sharkey, & Clint Lowery',
	},
	{
		time: '8:40 PM ET',
		title: 'Jam Break w/ Boscoe France and Mark Lettieri',
	},
	{
		time: '8:40 PM ET',
		title: 'Paul Reed Smith',
	},
	// More events...
];

export default function Schedule() {
	return (
		<div className="flex flex-col">
			<div className="-my-2 overflow-x-auto">
				<div className="py-2 align-middle inline-block min-w-full">
					<div className="shadow overflow-hidden border-b border-gray-200">
						<table className="min-w-full divide-y divide-gray-200">
							<thead className="bg-gray-50">
								<tr>
									<th
										scope="col"
										className="pr-6 pl-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
									>
										Sessions - Event Starts at 7PM ET
									</th>
								</tr>
							</thead>
							<tbody>
								{events.map((event, eventIdx) => (
									<tr
										key={event.time}
										className={
											eventIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'
										}
									>
										<td className="py-4 pr-4 pl-4 text-sm text-gray-500">
											{event.title}
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	);
}
