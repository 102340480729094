import React from 'react';
import Footer from './Footer';
import './../styles/style.css';

const Layout = ({ children }) => (
	<div className="bg-black main-container">
		{children}
		<Footer />
	</div>
);

export default Layout;
