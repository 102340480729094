import * as React from 'react';
import '../styles/style.css';
import Layout from '../components/Layout';
import Hero from '../components/Hero';
import Schedule from '../components/Schedule';
import Artists from '../components/Artists';
import JamArtists from '../components/JamArtists';
import Shirt from '../components/Shirt';
import Hopkins from '../components/Hopkins';

import FacebookLogo from './../images/facebook-logo.png';
import YouTubeLogo from './../images/youtube-logo.png';
import NewsletterLogo from './../images/newsletter-logo.png';
import TwitterLogo from './../images/twitter-logo.png';
import InstagramLogo from './../images/instagram-logo.png';

// markup
const IndexPage = () => {
	return (
		<Layout>
			<Hero />
			<section className="max-w-6xl mx-auto section-container pb-6 mb-12">
				<h2 className=" text-3xl font-bold p-4 mt-6 mb-10 section-title">
					About The Event
				</h2>

				<p className="text-lg md:text-xl mb-6 w-11/12 mx-auto">
					Experience PRS 2021 is going virtual on PRS Guitars YouTube
					Channel on Thursday, July 8th at 7:00 PM ET.
				</p>

				<p className="text-lg md:text-xl mb-6 w-11/12 mx-auto">
					We’re bringing you an inside look From the Factory Floor, artist
					clinics, live sessions with Paul Reed Smith, and more.{' '}
				</p>

				<p className="text-lg md:text-xl mb-6 w-11/12 mx-auto">
					<a href="https://prsguitars.com/newsletter">
						Sign up for our newsletter
					</a>
					, follow us on social, and revisit this page for future event
					updates.{' '}
				</p>

				<p className="text-white text-lg md:text-xl w-11/12 mx-auto">
					Visit the{' '}
					<a href="https://www.youtube.com/watch?v=HjYeEOpcvq0">
						YouTube event
					</a>{' '}
					to join the waiting room for Experience PRS 2021.
				</p>

				<div className="flex flex-wrap justify-around  max-w-xl mx-auto my-10">
					<div>
						<a href="https://prsguitars.com/newsletter">
							<img
								src={NewsletterLogo}
								alt="PRS Newsletter"
								className="w-1/2 mx-auto"
							/>
						</a>
					</div>
					<div>
						<a href="https://facebook.com/prsguitars">
							<img
								src={FacebookLogo}
								alt="Facebook"
								className="w-1/2 mx-auto"
							/>
						</a>
					</div>
					<div>
						<a href="https://www.youtube.com/watch?v=HjYeEOpcvq0">
							<img
								src={YouTubeLogo}
								alt="YouTube"
								className="w-1/2 mx-auto"
							/>
						</a>
					</div>
					<div>
						<a href="https://twitter.com/prsguitars">
							<img
								src={TwitterLogo}
								alt="Twitter"
								className="w-1/2 mx-auto"
							/>
						</a>
					</div>
					<div>
						<a href="https://instagram.com/prsguitars">
							<img
								src={InstagramLogo}
								alt="Instagram"
								className="w-1/2 mx-auto"
							/>
						</a>
					</div>
				</div>
			</section>

			<section className="max-w-6xl mx-auto my-12">
				<div className="video-container">
					<iframe
						src="https://www.youtube.com/embed/Amx7kxn9Dho"
						frameBorder="0"
						allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
						allowFullScreen
						title="Experience PRS 2021 Invitation with Paul"
					></iframe>
				</div>
			</section>

			<section className="max-w-6xl mx-auto my-12">
				<h2 className="text-3xl font-bold p-4 my-6 section-title">
					Schedule
				</h2>
				<div className="my-6">
					<Schedule />
				</div>
			</section>

			<section className="max-w-6xl mx-auto my-12">
				<h2 className="text-3xl font-bold p-4 my-6 section-title">
					Clinicians &amp; Demonstrators
				</h2>

				<Artists />
			</section>

			<section className="max-w-6xl mx-auto section-container pb-6 my-12">
				<h2 className="text-3xl font-bold p-4 my-6 mb-10 section-title">
					Jam Stage
				</h2>
				<p className="text-lg md:text-xl mb-6 w-11/12 mx-auto">
					Have your favorite guitar handy! We will be hosting two “Jam
					Stage” Breaks between sessions.
				</p>

				<p className="text-lg md:text-xl mb-6 w-11/12 mx-auto">
					Need to warm up? Check out our{' '}
					<a href="https://prsguitars.com/blog/post/join_the_play_at_home_challenge">
						Play at Home
					</a>{' '}
					tracks to get in some rehearsal time.
				</p>

				<JamArtists />
			</section>
			<Shirt />
			<Hopkins />
		</Layout>
	);
};

export default IndexPage;
