import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

const JamArtists = () => {
	return (
		<section className="max-w-6xl mx-auto section-container pb-6 my-12">
			<div className="flex flex-wrap pt-6">
				<div className="w-full md:w-1/2 lg:w-1/4 mb-5 md:mb-0 mx-auto">
					<StaticImage
						src="./../images/mark-lettieri.jpg"
						alt="Mark Lettieri"
						placeholder="blurred"
						layout="constrained"
						width={800}
						className="m-3 border-2 border-white"
					/>
					<h3 className="text-primary-red font-bold text-2xl uppercase mx-3">
						Mark Lettieri
					</h3>
					<p className="mx-3">Jam Break Artist</p>
				</div>
				<div className="w-full md:w-1/2 lg:w-1/4 mb-5 md:mb-0 mx-auto">
					<StaticImage
						src="./../images/tyler-larson.jpg"
						alt="Tyler Larson"
						placeholder="blurred"
						layout="constrained"
						width={800}
						className="m-3 border-2 border-white"
					/>
					<h3 className="text-primary-red font-bold text-2xl uppercase mx-3">
						Tyler Larson
					</h3>
					<p className="mx-3">Jam Break Artist</p>
				</div>

				<div className="w-full md:w-1/2 lg:w-1/4 mb-5 md:mb-0 mx-auto">
					<StaticImage
						src="./../images/bf.jpg"
						alt="Boscoe France"
						placeholder="blurred"
						layout="constrained"
						width={800}
						className="m-3 border-2 border-white"
					/>
					<h3 className="text-primary-red font-bold text-2xl uppercase mx-3">
						Boscoe France
					</h3>
					<p className="mx-3">Jam Break Artist</p>
				</div>
				<div className="w-full md:w-1/2 lg:w-1/4 mb-5 md:mb-0 mx-auto">
					<StaticImage
						src="./../images/bryan-ewald.jpg"
						alt="Bryan Ewald"
						placeholder="blurred"
						layout="constrained"
						width={800}
						className="m-3 border-2 border-white"
					/>
					<h3 className="text-primary-red font-bold text-2xl uppercase mx-3">
						Bryan Ewald
					</h3>
					<p className="mx-3">Jam Break Artist</p>
				</div>
			</div>
		</section>
	);
};

export default JamArtists;
