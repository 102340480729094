import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

const Hopkins = () => {
	return (
		<section className="max-w-6xl mx-auto section-container pb-6 my-12">
			<h2 className="text-3xl font-bold p-4 my-6 mb-10 section-title">
				Johns Hopkins Kimmel Cancer Center
			</h2>
			<p className="text-lg md:text-xl mb-6 w-11/12 mx-auto">
				This Experience, PRS Guitars invites you help support our flagship
				charity: The Johns Hopkins Kimmel Cancer Center.
			</p>
			<p className="text-lg md:text-2xl font-bold mb-6 w-11/12 mx-auto pt-3">
				Two Ways To Support
			</p>
			<ul className="list-disc ml-5">
				<li className="text-lg md:text-xl mb-6 w-11/12 mx-auto text-white">
					<p>
						Make a donation now at the Experience PRS 2021 “GIVE NOW” tip
						jar:{' '}
						<a href="https://aspire.hopkinsmedicine.org/project/26645">
							Kimmel Cancer Center | Experience PRS 2021
						</a>
						<br></br>
						This site will remain open through Sunday, 7/11.
					</p>
					<div className="mx-auto text-center p-6">
						<a
							href="https://aspire.hopkinsmedicine.org/project/26645"
							className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-primary-red hover:bg-red-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-red uppercase cursor-pointer"
						>
							Give Now
						</a>
					</div>
				</li>
				<li className="text-lg md:text-xl mb-6 w-11/12 mx-auto text-white">
					<p>
						Also in conjunction with PRS Guitars Experience PRS 2021
						virtual event, Eagles guitarist Joe Walsh has donated several
						items to benefit the Johns Hopkins Kimmel Cancer Center,
						including a{' '}
						<i>PRS McCarty 594 Singelcut Joe Walsh Limited Edition</i>{' '}
						guitar from his personal collection with a hand-penned note
						from Walsh himself and #3284/5000 of a History of The Eagles
						Super Deluxe Limited Edition box set. This special grouping of
						items will be{' '}
						<a
							className=""
							href="https://reverb.com/item/41790297-paul-reed-smith-joe-walsh-autographed-limited-edition-mccarty-594-singlecut-2021-mccarty-sunburst"
						>
							available for purchase on Reverb.com
						</a>{' '}
						starting at 7:00PM ET on July 8, 2021 with all proceeds
						benefiting the Johns Hopkins Kimmel Cancer Center.
					</p>
					<div className="mx-auto text-center p-6">
						<a
							href="https://reverb.com/item/41790297-paul-reed-smith-joe-walsh-autographed-limited-edition-mccarty-594-singlecut-2021-mccarty-sunburst"
							className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-primary-red hover:bg-red-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-red uppercase cursor-pointer"
						>
							Visit Reverb
						</a>
					</div>
				</li>
			</ul>

			<p className="text-lg md:text-xl mb-6 w-11/12 mx-auto">
				Since its opening in 1973, the Johns Hopkins Kimmel Cancer Center
				(Baltimore, Maryland) has led the world in studying cancer and
				developing new ways to treat it. Since 2000, PRS Guitars has raised
				more than $3 million for the Living with Cancer Resource Program.
				Focused on the supportive care needs of cancer patients and their
				families this important program provides emotional support,
				assistance with temporary housing and transportation, help with
				finances and legal matters and spiritual care – all free of charge.
			</p>

			<div className="max-w-6xl mx-auto my-12">
				<div className="video-container">
					<iframe
						src="https://www.youtube.com/embed/4vM4adFRis4"
						frameBorder="0"
						allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
						allowFullScreen
						title="Hopkins Kimmel Cancer Center Video"
					></iframe>
				</div>
			</div>
		</section>
	);
};

export default Hopkins;
