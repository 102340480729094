import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

const Artists = () => {
	return (
		<section className="max-w-6xl mx-auto section-container pb-6 my-12">
			<div className="flex flex-wrap justify-start pt-6">
				<div className="w-full sm:w-1/2 md:w-1/3 mb-5 md:mb-0">
					<StaticImage
						src="./../images/tenile-arts.jpg"
						alt="Tenille Arts"
						placeholder="blurred"
						layout="constrained"
						width={800}
						className="m-3 border-2 border-white"
					/>
					<h3 className="text-primary-red font-bold text-2xl uppercase mx-3">
						Tenille Arts
					</h3>
					<p className="mx-3">Approaching Songwriting</p>
				</div>
				<div className="w-full sm:w-1/2 md:w-1/3 mb-5 md:mb-0">
					<StaticImage
						src="./../images/justin-derrico.jpg"
						alt="Justin Derrico"
						placeholder="blurred"
						layout="constrained"
						width={800}
						className="m-3 border-2 border-white"
					/>
					<h3 className="text-primary-red font-bold text-2xl uppercase mx-3">
						Justin Derrico
					</h3>
					<p className="mx-3">
						Exploring 2 Timeless Tones with the HX Amplifier
					</p>
				</div>
				<div className="w-full sm:w-1/2 md:w-1/3 mb-5 md:mb-0">
					<StaticImage
						src="./../images/clint-lowery.jpg"
						alt="Clint Lowery"
						placeholder="blurred"
						layout="constrained"
						width={800}
						className="m-3 border-2 border-white"
					/>
					<h3 className="text-primary-red font-bold text-2xl uppercase mx-3">
						Clint Lowery
					</h3>
					<p className="mx-3">Writing in Alternate Tunings</p>
				</div>
				<div className="w-full sm:w-1/2 md:w-1/3 mb-5 md:mb-0">
					<StaticImage
						src="./../images/ben-eller.jpg"
						alt="Ben Eller"
						placeholder="blurred"
						layout="constrained"
						width={800}
						className="m-3 border-2 border-white"
					/>
					<h3 className="text-primary-red font-bold text-2xl uppercase mx-3">
						Ben Eller
					</h3>
					<p className="mx-3">PRS Archon</p>
				</div>
				<div className="w-full sm:w-1/2 md:w-1/3 mb-5 md:mb-0">
					<StaticImage
						src="./../images/wes-hauch.jpg"
						alt="Wes Hauch"
						placeholder="blurred"
						layout="constrained"
						width={800}
						className="m-3 border-2 border-white"
					/>
					<h3 className="text-primary-red font-bold text-2xl uppercase mx-3">
						Wes Hauch
					</h3>
					<p className="mx-3">PRS Archon</p>
				</div>
				<div className="w-full sm:w-1/2 md:w-1/3 mb-5 md:mb-0">
					<StaticImage
						src="./../images/corey-congilio.jpg"
						alt="Corey Congilio"
						placeholder="blurred"
						layout="constrained"
						width={800}
						className="m-3 border-2 border-white"
					/>
					<h3 className="text-primary-red font-bold text-2xl uppercase mx-3">
						Corey Congilio
					</h3>
					<p className="mx-3">A Blues Rhythm Concept</p>
				</div>
				<div className="w-full sm:w-1/2 md:w-1/3 mb-5 md:mb-0">
					<StaticImage
						src="./../images/isaiah-sharkey.jpg"
						alt="Isaiah Sharkey"
						placeholder="blurred"
						layout="constrained"
						width={800}
						className="m-3 border-2 border-white"
					/>
					<h3 className="text-primary-red font-bold text-2xl uppercase mx-3">
						Isaiah Sharkey
					</h3>
					<p className="mx-3">Playing Outside &quot;The Box&quot;</p>
				</div>
			</div>
		</section>
	);
};

export default Artists;
