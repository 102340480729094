import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

const Shirt = () => {
	return (
		<section className="max-w-6xl mx-auto section-container pb-6 my-12">
			<h2 className="text-3xl font-bold p-4 my-6 mb-10 section-title">
				Limited Edition Event T-Shirt
			</h2>
			<p className="text-lg md:text-xl mb-6 w-11/12 mx-auto">
				Preorder Closed. Shirts will ship in August 2021. Thank you!
			</p>
			<div className="mx-auto w-full lg:w-4/5 ">
				<StaticImage
					src="./../images/t-shirt.jpg"
					alt="Experience PRS T-Shirt"
					placeholder="blurred"
					layout="constrained"
					width={1200}
					className="mb-6 transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-101 cursor-pointer"
				/>
			</div>
		</section>
	);
};

export default Shirt;
