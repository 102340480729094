import React from 'react';
import FacebookLogo from './../images/facebook-logo.png';
import YouTubeLogo from './../images/youtube-logo.png';

const Hero = () => {
	return (
		<section className="max-w-6xl mx-auto pb-12">
			<div className="max-w-6xl mx-auto">
				<div className="video-container">
					<iframe
						src="https://www.youtube.com/embed/HjYeEOpcvq0"
						frameBorder="0"
						allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
						allowFullScreen
						title="Experience PRS 2021 Invitation with Paul"
					></iframe>
				</div>
			</div>
		</section>
	);
};

export default Hero;
