import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

const Footer = () => {
	return (
		<footer className="max-w-6xl mx-auto pt-12 pb-8 text-center">
			<p>
				<a href="https://prsguitars.com">
					<StaticImage
						src="../../src/images/prs-logo.png"
						alt="PRS Guitars"
						placeholder="blurred"
						layout="fixed"
						width={250}
						height={102}
						className="mx-auto mb-6"
					/>
				</a>
			</p>
			<p className="text-white text-xl text-center mx-auto">
				&copy; PRS Guitars 2021
			</p>
			<p className="text-white text-xl text-center mx-auto">
				<a href="https://www.prsguitars.com">www.prsguitars.com</a>
			</p>
		</footer>
	);
};

export default Footer;
